// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "code" ]

  reset_code() {
    let confirmDialog = confirm("Zugangscode neu generieren? Die Änderung wird nach der Speicherung des Dozentendatensatzes aktiv.")
    if (confirmDialog == true) {
      let str1 = Math.random().toString(36).replace(/\./g, '')
      let str2 = Math.random().toString(36).replace(/\./g, '')
      this.codeTarget.value = str1+str2;
    }  
  }
}
